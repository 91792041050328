//
// Social buttons
// --------------------------------------------------


// Base styles

.btn-social {
  display: inline-block;
  width: $btn-social-basic-size;
  height: $btn-social-basic-size;
  padding: initial;
  border: $btn-social-border-width solid transparent;
  color: $btn-social-dark-color;
  transition: $btn-social-transition;
  @include font-size($btn-social-basic-font-size);
  text: {
    align: center;
    decoration: none;
  }

  &:hover { color: $btn-social-dark-hover-color; }
  &:focus { outline: none; }
  &:active {
    transition: none;
    box-shadow: none;
  }

  &:not(.rounded-circle) {
    @include border-radius($btn-social-border-radius);
  }

  &.bs-light {
    color: $btn-social-basic-color;
    &:hover { color: $btn-social-basic-hover-color; }
  }

  &.bs-solid,
  &.bs-outline {
    width: $btn-social-size;
    height: $btn-social-size;
    @include font-size($btn-social-font-size);
    > i { line-height: $btn-social-size - .125rem; }
  }


  // Solid variant

  &.bs-solid {
    background-color: $btn-social-dark-bg;
    &:hover { background-color: $btn-social-dark-hover-bg; }

    &.bs-light {
      background-color: $btn-social-solid-light-bg;
      color: $btn-social-solid-light-color;
      &:hover {
        background-color: $btn-social-solid-light-hover-bg;
        color: $btn-social-solid-light-hover-color;
      }
    }
  }


  // Outline variant

  &.bs-outline {
    border-color: $btn-social-dark-border-color;
    background-color: transparent;
    &:hover { border-color: var(--#{$prefix}primary); }

    &.bs-light {
      border-color: $btn-social-outline-light-border-color;
      background-color: $btn-social-outline-light-bg;
      color: $btn-social-outline-light-color;
      &:hover {
        border-color: $btn-social-outline-light-hover-border;
        color: $btn-social-outline-light-hover-color;
      }
    }
  }
}
