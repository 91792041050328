//
// Mixins
// --------------------------------------------------


// Font smoothing

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Appearance

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}


// Gradient background

@mixin gradient-background($gradient-from: $gradient-from-color, $gradient-to: $gradient-to-color) {
  background: $gradient-from !important;
  background: linear-gradient(55.95deg, $gradient-from 0%, $gradient-to 100%) !important;
}


// Multiple items easing

@mixin multi-easing($count-to, $duration, $delay-factor, $easing) {
  @for $i from 1 through $count-to {
    &:nth-child(#{$i}) {
      transition: all #{$duration}s #{$i * $delay-factor}s $easing;
    }
  }
}
