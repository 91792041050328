//
// Dropdown
// --------------------------------------------------


// Caret

.dropdown-toggle {
  &::after,
  &::before {
    display: inline-block;
    font: {
      family: $icons-font-family;
      weight: normal;
    }
    vertical-align: middle;
  }

  &::after {
    margin: {
      top: -.0625rem;
      left: .375rem;
    }
    border: 0;
    content: $ci-angle-down;
  }

  &::before { margin-right: .25rem; }
}

.dropdown-toggle:not(.dropdown-toggle-split) {
  &::before { margin-left: -.25rem; }
  &::after { margin-right: -.25rem; }
}

.dropdown .dropdown-toggle,
.dropup .dropdown-toggle,
.dropend .dropdown-toggle,
.dropstart .dropdown-toggle {
  &::before,
  &::after {
    vertical-align: middle;
    border: 0;
  }
}

.dropdown .dropdown-toggle,
.dropup .dropdown-toggle,
.dropend .dropdown-toggle {
  &::before { display: none; }
}

.dropup .dropdown-toggle::after {
  content: $ci-angle-up;
}

.dropend .dropdown-toggle::after {
  content: $ci-angle-right;
  vertical-align: bottom;
}

.dropstart .dropdown-toggle {
  &::before {
    content: $ci-angle-left;
    vertical-align: bottom;
  }
  &::after { display: none; }
}


// Dropdown menu

.dropdown-menu {
  @include box-shadow($dropdown-box-shadow);

  li {
    margin-bottom: 0;
    &:hover > .dropdown-item {
      color: $dropdown-link-hover-color;
    }
  }

  .active > .dropdown-item {
    color: $dropdown-link-active-color;
  }
}


// Dropdown header

.dropdown-header {
  margin-bottom: .5rem;
  padding: {
    top: $dropdown-item-padding-y;
    bottom: $dropdown-item-padding-y * 3;
  }
  border-bottom: $dropdown-border-width solid $dropdown-divider-bg;
  @include font-size($dropdown-header-font-size);
  font-weight: $headings-font-weight;
}


// Dropdown menu animation

.dropdown-menu.show {
  animation: fade-in .25s ease-in-out;
}


// Split button dropdown fix

.dropdown-toggle-split::after {
  margin-left: -.0625rem !important;
}


// Dropdown component animations

@keyframes fade-in  {
  from { opacity: 0; }
  to { opacity: 1; }
}
