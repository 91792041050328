//
// Alert
// --------------------------------------------------


// Alert theme colors

.alert-secondary {
  border-color: $border-color;
  background-color: $secondary;
}

.alert-secondary,
.alert-light {
  color: $body-color;

  .alert-link { color: $gray-800; }
}

.alert-dark {
  border-color: $dark;
  background-color: $dark;
  color: $white;

  .alert-link { color: $white; }
}


// Alert with icon

.alert {
  [class^='ci-'], [class*=' ci-'] {
    margin-top: .125rem;
  }
}


// Alert dismissible

.alert-dismissible .btn-close {
  padding: $alert-padding-y * 1.45 $alert-padding-x;
}
