//
// Animations
// --------------------------------------------------


// General

.from-top, .from-bottom,
.from-start, .from-end,
.scale-up, .scale-down,
.fade-in {
  transition: all .4s .3s ease-in-out;
  opacity: 0;
  backface-visibility: hidden;
}
.from-top {
  transform: translateY(-2.25rem);
}
.from-bottom {
  transform: translateY(2.25rem);
}
.from-start {
  transform: translateX(-2.25rem);
}
.from-end {
  transform: translateX(2.25rem);
}
.scale-up {
  transform: scale(0.85);
}
.scale-down {
  transform: scale(1.15);
}


// Utils

.delay-1 { transition-delay: .5s; }
.delay-2 { transition-delay: .7s; }
.delay-3 { transition-delay: .9s; }
.delay-4 { transition-delay: 1.1s; }


// Trigger

.tns-slide-active {
  .from-top, .from-bottom {
    transform: translateY(0);
    opacity: 1;
  }
  .from-start, .from-end {
    transform: translateX(0);
    opacity: 1;
  }
  .scale-up, .scale-down {
    transform: scale(1);
    opacity: 1;
  }
  .fade-in { opacity: 1; }
}


// Binded content
//
// Bind different content to different navs or even accordion.

.binded-content {
  position: relative;
}
.binded-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: opacity, transform;
  transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  transform: scale(.95);
  opacity: 0;
  pointer-events: none;
  cursor: default;
  &.active {
    position: relative;
    transform: none;
    opacity: 1;
    z-index: 5;
    cursor: initial;
    pointer-events: initial;
  }
}
