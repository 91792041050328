//
// Offcanvas
// --------------------------------------------------


// Offcanvas Header

.offcanvas-header {
  &.bg-dark, & {
    background-color: rgba($white, .05);
  }
}


// Offcanvas body (content holder)

.offcanvas-body {
  display: block !important;
  height: 100%;
  overflow-y: auto !important;

  // Custom scrollbar inside offcanvas
  &::-webkit-scrollbar {
    width: $scrollbar-width;
    background-color: transparent;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $scrollbar-bg;
    @include border-radius($scrollbar-width * .5);
  }
  & > .simplebar-track {
    display: block;
    background-color: transparent;
  }
  .simplebar-vertical { margin-right: .1875rem; }

  // Nav inside offcanvas
  .nav {
    flex-direction: column;
    > li { margin-bottom: 0; }
  }
  .nav-link {
    padding: {
      top: .25rem;
      bottom: .25rem;
    }
    &.active {
      position: relative;
      &::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: .125rem;
        height: 100%;
        content: '';
      }
    }
  }
  .nav-dark .nav-link.active::before {
    background-color: var(--#{$prefix}nav-link-active-color);
  }
  .nav-light .nav-link.active::before {
    background-color: var(--#{$prefix}nav-link-active-color);
  }
}

.offcanvas-docs .offcanvas-body .nav-link {
  font-weight: normal;
}


// Sidebar toggle btn

.sidebar-toggle {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
  z-index: $zindex-btn-scroll-top;

  &.right-15 {
    right: 15px;
    width: calc(100% - 15px);
  }
}


// Adding extra bottom spacing to accomodate sidebar toggle

.is-sidebar {
  @include media-breakpoint-down(lg) {
    .footer { padding-bottom: 2.25rem; }
    .sidebar-toggle { display: inline-flex; }
    .btn-scroll-top { bottom: $spacer + 2.25rem; }
  }
}


// Offcanvas enabled container (for layouts with fixed side navigation)
@include media-breakpoint-up(lg) {
  .offcanvas-enabled-start {
    padding-left: $offcanvas-horizontal-width + 1.5rem;
  }
  .offcanvas-enabled-end {
    padding: {
      right: $offcanvas-horizontal-width + 1.5rem;
      left: 0;
    }
  }
}
